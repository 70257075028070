import React, { useEffect } from 'react'
import CareEmployees from '../components/career/CareEmployees';
import Careerinfo from '../components/career/Careerinfo';
import Opening from '../components/career/Opening';
import Team from '../components/career/Team';
import Layout from '../components/Layout';
import Hero from '../components/Mobileapp/hero';
import Seo from '../components/Seo';
import allApiData from '../api';
import SeoheadData from '../siteScriptData';

const buttonsectiondata = {
    title: 'Do you have what it takes to be a part of a growing organization? ',
    sub_title: 'Let Vasundhara be the next step of your career trajectory and personal development.',
    button: 'Join Vasundhara'
}

const Career = () => {
    allApiData.removeanimation();
    useEffect(() => {
        allApiData.aosinit()
    }, []);
    return (
        <Layout sectionData={buttonsectiondata}>
            <Seo SeoData={SeoheadData.careersPageSeoData}></Seo>
            <main >
                <div className="career-img">
                    <Hero title="Growth at Vasundhara"
                        button_t={false}
                        cta='Check Current Openings'
                        def="Are you a developer, game tester, or passionate about providing solutions? If yes, then Vasundhara is the right place for your career growth. We are welcoming talented professionals from across the globe to work with us.">
                        <div className="hero-img hide-animation-768">
                            <img src="../assets/img/career/hero_img/growth-at-vasundhara.svg" className="wh-auto z-index img-fluid" alt="growth-at-vasundhara"
                            width="743" height="482"      />
                            <img src="../assets/img/career/hero_img/1.svg" className="career-img-1 img-fluid" alt="growth-at-vasundhara-one" />
                            <img src="../assets/img/career/hero_img/1.svg" className="career-img-2 img-fluid" alt="growth-at-vasundhara-one" />
                            <img src="../assets/img/career/hero_img/1.svg" className="career-img-3 img-fluid" alt="growth-at-vasundhara-one" />
                        </div>
                        <div className="show-main-img-768">
                            <img src="../assets/img/career/hero_img/growth-at-vasundhara-mobile.svg" className="wh-auto img-fluid" alt="growth-at-vasundhara-mobile" width="680" height="441" />
                        </div>
                    </Hero>
                </div>
                <Team />
                <Opening />
                <Careerinfo />
                <div className="care-em">
                    <CareEmployees />
                </div>
            </main>
        </Layout>
    )
}

export default Career
