import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import allApiData from '../../api';

const Team = () => {
    const [getTeamData, setTeamData] = useState([]);
    useEffect(() => {
        allApiData.careerTeams().then((team) => {
            setTeamData(team.data);
        })
    }, [])
    return (
        <>
            <section className='our-team'>
                <Container>
                    <div className='heading' data-aos="zoom-in">
                        <h2>Team</h2>
                    </div>
                    <Row className='mt-sm-5 mt-4 justify-content-center'>
                        {
                            getTeamData && getTeamData.map((data, i) => (
                                <Col xl={3} lg={4} md={6} sm={12} xs={12} key={i} className="mt-3" >
                                    <div className='team-box' data-aos="zoom-in-down">
                                        <div className='team-img'>
                                            <img src={`${data.image_url}`} alt={data.name} width="250" height="250" className='wh-auto' />
                                        </div>
                                        <h5>{data.name}</h5>
                                        <span>{data.designation}</span>
                                    </div>
                                </Col>
                            ))
                        }
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Team