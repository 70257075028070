import React from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import allData from '../../allData.js'
function CareEmployees() {
    return (
        <div className='care pb-100'>
            <Container>
                <div className="heading" data-aos="zoom-in">
                    <h2>Why Join Vasundhara?</h2>
                </div>
                <Row className='mt-5'>
                    {
                        allData && allData.Employees.map((e, i) => {
                            return (
                                <Col key={i} lg={6} className="my-sm-3 my-2">
                                    <Card data-aos="zoom-out-down">
                                        <div className='card-img-box'>
                                            <img src={e.img} alt={e.title} width="100" height="100" className='wh-auto' />
                                        </div>
                                        <div className="card-content ">
                                            <h3> {e.title} </h3>
                                            <p> {e.content} </p>
                                        </div>
                                    </Card>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>
        </div>
    )
}

export default CareEmployees