import React, { useEffect, useState } from 'react'
import { Button, Collapse, Container } from 'react-bootstrap';
import Applynowmodel from '../Applynowmodel'
import allApiData from '../../api';

const Opening = () => {
    const [getOpingData, setOpingData] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [open, setOpen] = useState(undefined);

    useEffect(() => {
        allApiData.openingData().then((res) => {
            setOpingData(res.data);
            const initialState = res.data.reduce((a, v) => ({ ...a, [v.id]: false }), {});
            setOpen(initialState);
        })
    }, []);

    const handleToggle = (_potid) => { setModalShow(!modalShow); };
    let handleJobToggle = (id) => {
        for (let key in open) {
            if (key == id) {
                setOpen((prev) => { return { ...prev, [id]: !open[id] } });
            } else {
                setOpen((prev) => { return { ...prev, [key]: false } });
            }
        }
    }

    return (
        <>
            <section className='current-opening' id='opening'>
                <Container>
                    <div className='heading' data-aos="zoom-in">
                        <h2>Current Open Positions at Vasundhara</h2>
                    </div>
                    <div className='current-main-con mt-5 pt-lg-2'>
                        {
                            getOpingData && getOpingData.map((data, i) => (
                                <div key={i} className='aws-specialist mt-3' data-aos="zoom-in-down" >
                                    <div className='aws-box'>
                                        <div className='open-content'>
                                            <div className='tech-logo'>
                                                <img src={`https://outsourcingvasundhara.sfo3.cdn.digitaloceanspaces.com/${data.icon}` ? `https://outsourcingvasundhara.sfo3.cdn.digitaloceanspaces.com/${data.icon}` : `../../assets/img/career/current-openings/default-career.png`} alt={data.job_name} />
                                            </div>
                                            <div className='tech-title'>
                                                <h5 className='ps-3 mb-2'>{data.job_name}</h5>
                                                <div className=' job-info'>
                                                    <div className=' years'>
                                                        <h6>Experience: <span>{data.experience}</span></h6>
                                                    </div>
                                                    <div className=' years'>
                                                        <h6>No. of Openings: <span>{data.no}</span></h6>
                                                    </div>
                                                    <div className=' years'>
                                                        <h6>Qualifications: <span>{data.qualification}</span></h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="apply-btn-div me-sm-3 ">
                                                <Button className='apply-now' onClick={() => handleJobToggle(data.id)} aria-controls={data.id} aria-expanded={data.id}>{'View Details'}</Button>
                                            </div>
                                            <div className="apply-btn-div">
                                                <button className='apply-now' onClick={() => handleToggle()}>{'Apply Now'}</button>
                                            </div>
                                        </div>
                                    </div>

                                    <Collapse in={open != undefined ? open[data.id] : null}>
                                        <div className='mt-3 pt-lg-3 career-sub-def'>
                                            <div className='job-location'>
                                                <h5>Job Location</h5>
                                                <ul>
                                                    {
                                                        data.location_array && data.location_array.map((loc, i) => (
                                                            <li key={i}><span>{loc}</span></li>
                                                        ))
                                                    }
                                                </ul>
                                            </div>
                                            <div className='job-location mt-4'>
                                                <h5>Responsibilities</h5>
                                                <ul>
                                                    {
                                                        data.responsibilities_array && data.responsibilities_array.map((exp, i) => {
                                                            return (
                                                                <li key={i}><span>{exp}</span></li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                            <div className='job-location mt-4'>
                                                <h5>Skills Required</h5>
                                                <ul>
                                                    {
                                                        data.skill_array && data.skill_array.map((skill, i) => (
                                                            <li key={i}><span>{skill}</span></li>
                                                        ))
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                            ))
                        }
                    </div>
                    <div className='open-form'>
                        <Applynowmodel showVal={modalShow} handleToggle={handleToggle} />
                    </div>
                </Container>
            </section>
        </>
    )
}

export default Opening