import React from 'react'
import { Col, Container, Row, } from 'react-bootstrap'

function Careerinfo() {
    return (
        <div>
            <div>
                <div className="about-printing career-info" id="">
                    <Container>
                        <Row className='align-items-center  justify-content-between'>
                            <Col lg={6} md={12} className="order-lg-1 order-2 " >
                                <div className="hero-img hide-animation-768" data-aos="fade-right">
                                    <img src="../assets/img/career/career-at-vasundhara-infotech/how_are_career.svg" className="wh-auto z-index img-fluid" alt="how_are_career"
                                        width="648" height="536"
                                    />
                                    <img src="../assets/img/career/career-at-vasundhara-infotech/1.svg"
                                        className="z-index img-fluid career-info-img1 " alt="how_are_career-1" />
                                    <img src="../assets/img/career/career-at-vasundhara-infotech/1.svg"
                                        className="z-index img-fluid career-info-img2 " alt="how_are_career-1" />

                                    <img src="../assets/img/career/career-at-vasundhara-infotech/2.svg"
                                        className="z-index img-fluid career-info-img3 " alt="how_are_career-2" />
                                    <img src="../assets/img/career/career-at-vasundhara-infotech/2.svg"
                                        className="z-index img-fluid career-info-img4 " alt="how_are_career-2" />
                                    <img src="../assets/img/career/career-at-vasundhara-infotech/2.svg"
                                        className="z-index img-fluid career-info-img5 " alt="how_are_career-2" />

                                </div>
                                <div className="show-main-img-768">
                                    <img src="../assets/img/career/career-at-vasundhara-infotech/how_are_career_mobile.svg" className="wh-auto  img-fluid" alt="how_are_career_mobile"
                                        width="648" height="536"
                                    />
                                </div>
                            </Col>
                            <Col lg={6} md={12} className="order-lg-1 order-2">
                                <div className="about-printing-content  " >
                                    <div className="heading" data-aos="zoom-in">
                                        <h2 className='text-uppercase'>How are Careers @Vasundhara?</h2>
                                    </div>

                                    <ul>
                                        <li data-aos="fade-left">Be part of a team of dedicated tech professionals who change and care for each other on a daily basis at every stage of your career.</li>
                                        <li data-aos="fade-right">Join our friendly team and take the challenge. Let’s start businesses and help new entrepreneurs.</li>
                                        <li data-aos="fade-left">Be part of an experienced team of highly trained IT professional experts.</li>
                                        <li data-aos="fade-right">As the development grows day by day, we are especially looking for new, small, innovative, and imaginative minds.</li>
                                        <li data-aos="fade-left">Please contact us if you think you would like to work as a developer.</li>
                                        <li data-aos="fade-right">Be a part of our team in a diverse work atmosphere, focused on fostering business by delivering outstanding service.</li>
                                        <li data-aos="fade-left">To broaden your learning, experience, and abilities, get an opportunity in our organization.</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    )
}

export default Careerinfo